import styled from 'styled-components';
import downloadButton from './download-button.svg';  // change to your actual path
import logo from '../logo.svg'

const Background = styled.div`
    background: linear-gradient(0deg, #0062ff, #da61ff);
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const AppIcon = styled.img`
    height: 220px;
    width: 300px;
    object-fit: cover;
    margin-bottom: 20px;
`;

const DownloadButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
`;

const Button = styled.a`
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: white;
    text-decoration: none;
`;

const AppButton = styled.a`
    margin-bottom: 5px;
    color: white;
    text-decoration: none;
`;

function HomePage() {
    return (
      <Background>
        <Button>
            <AppButton href="/terms">Terms</AppButton>
            <AppButton href="/privacy">Privacy</AppButton>
            <AppButton href="mailto:littleappsxyz@gmail.com">Contact Us</AppButton>
        </Button>
        <AppIcon src={logo} alt="app icon" />
        <DownloadButton href="https://apps.apple.com/us/app/wys-prompts-for-friends/id6452384298" target="_blank" rel="noopener noreferrer">
          <img src={downloadButton} alt="Download on the app store" style={{ width: '200px', height: '60px' }} />
        </DownloadButton>
      </Background>
    );
  }
  
  export default HomePage;