import React from 'react';

const TermsOfService = () => {
  return (
    <div>
      <h1>Terms of Service</h1>
      <p>
        These terms of service ("Terms") govern your access to and use of the WYS mobile application ("App") provided by Alex Little ("Developer"). By accessing or using the App, you agree to be bound by these Terms.
      </p>
      <h2>License</h2>
      <p>
        The App is licensed, not sold, to you for use only under the terms of this license. The Developer reserves all rights not expressly granted to you. You may not modify, reverse engineer, decompile or disassemble the App, except as permitted by law.
      </p>
      <h2>Disclaimer of Warranty</h2>
      <p>
        The App is provided "as is" without warranty of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose. The Developer does not warrant that the App will meet your requirements or that the operation of the App will be uninterrupted or error-free.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        In no event shall the Developer be liable for any direct, indirect, incidental, special or consequential damages arising out of or in connection with the use of the App, even if the Developer has been advised of the possibility of such damages.
      </p>
      <h2>Changes to Terms</h2>
      <p>
        The Developer reserves the right to modify these Terms at any time. Your continued use of the App after any such changes constitutes your acceptance of the new Terms.
      </p>
      <h2>Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of UK, without regard to its conflict of law provisions.
      </p>
    </div>
  );
};

export default TermsOfService;