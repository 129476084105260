import { useState, useEffect, DocumentMeta } from 'react';
import getData from '../firebase/firestore/getData';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { FieldValue, serverTimestamp } from 'firebase/firestore';
import addData from '../firebase/firestore/addData';
import downloadButton from './download-button.svg';  // change to your actual path
import logo from '../logo.svg'

const Background = styled.div`
    background: linear-gradient(0deg, #0062ff, #da61ff);
    height: 100vh;
    width: 100vw;
`;

const TopContainer = styled.div`
    width: 85%;
    max-width: 640px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TopForm = styled.form`
    width: 100%;
`;

const Bubble = styled.div`
    display: block;
    margin-top: 44px;
    border-radius: 24px;
`;

const BubbleHeader = styled.div`
    display: flex;
    align-items: top;
    justify-content: left;
`;

const BubbleContainerHeader = styled.div`
    padding: 16px 16px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    background-color: white;
    border-radius: 32px 32px 0 0;
`;

const BubblePFPContainer = styled.div`
    border-radius: 300px;
    width: 40px;
    height: 40px;
    background-color: #F1F1F1;
    background-image: url(/images/profilePlaceholder.png);
    background-position: center center;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    display: block;
`;
const BubblePFPImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 20px;
    overflow-clip-margin: content-box;
    overflow: clip;
`;

const BubbleEmoji = styled.div`
    margin-right: 12px;
    font-size: 30px;
`;

const BubbleUserContainer = styled.div`
    margin-left: 12px;
    font-size: 14px;
`;

const Username = styled.div`
    font-weight: 500;
`;

const Prompt = styled.div`
    font-weight: bold;
`;

const BubbleTextContainer = styled.div`
    width: 100%;
    margin-bottom: 10px;
    background-color: rgba(255,255,255,0.4);
    backdrop-filter: blur(24px);
    -webkit-backdrop-filter: blur(6px);
    border-radius: 0 0 24px 24px;
    position: relative;
    -webkit-appearance: none;
`;

const BubbleTextArea = styled.textarea`
    width: 100%;
    padding: 18px 24px;
    height: 140px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 600;
    border: none;
    outline: none;
    background-color: transparent;
    color: black;
`;

const SubmitButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: black;
    border-radius: 100px;
    border: none;
    font-size: 18px;
    font-weight: 700;
    color: white;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0,0,0,0.25);
    height: 60px;
    box-sizing: border-box;
    cursor: pointer;
`

const BottomContainer = styled.div`
    bottom: calc(env(safe-area-inset-bottom) + 50px);
    position: absolute;
    width: 85%;
    max-width: 640px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 35px;
    padding-top: 32px;
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
`;

const InfoText = styled.h2`
    font-size: 16px;
    font-weight: 700;
    color: white;
    margin: 0;
    text-align: center;
`;

const Space = styled.div`
    height: 16px;
    width: 16px;
`;

const AnimatedButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: black;
    border-radius: 100px;
    border: none;
    font-size: 18px;
    font-weight: 700;
    color: white;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0,0,0,0.25);
    height: 60px;
    box-sizing: border-box;
    cursor: pointer;
    animation: pulse 2s ease-in-out infinite;

  @keyframes pulse {
    0%, 10%, 65%, 100% {
        transform: rotate(0deg) scale(1.0);
    }
    30%, 40% {
        transform: rotate(-1deg) scale(1.05);
    }
    35%, 45% {
        transform: rotate(1deg) scale(1.05);
    }
  }
`;

const AppIcon = styled.img`
    height: 220px;
    width: 300px;
    object-fit: cover;
    margin-bottom: 20px;
`;

const DownloadButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
`;

const Reply = () => {
    const { userId, post } = useParams(); 
    const [rizz, setRizz] = useState('');
    const [account, setAccount] = useState(null);
    const [prompt, setPrompt] = useState(null);

    const [focused, setFocused] = useState(false);

    const [hasSent, setHasSent] = useState(false);
    
    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)

    useEffect(() => {
        const fetchData = async () => {
            // Replace with your actual data fetching logic.
            const account = await getData('accounts', userId);
            const prompt = await getData(`accounts/${userId}/prompts`, post);

            console.log(account);
            setAccount(account);
            setPrompt(prompt);
        };

        fetchData();
    }, [userId]);

    const handleDownload = () => {
        // handle download app
    };

    if (!account || !prompt) { 
        return (<Background></Background>);
    }

    const handleSubmit = async () => {
        // // Add the rizz to the Firestore
        try {
            await addData(`accounts/${userId}/prompts/${post}/replies`, uuidv4(), {
                answer: rizz,
                timestamp: serverTimestamp()
            });
        } catch { }
        
        setRizz('');
        setHasSent(true);
    }

    if (hasSent) {
        return (
            <Background>
                <TopContainer>
                    <AppIcon src={logo} alt="app icon" />
                    <DownloadButton href="https://apps.apple.com/us/app/wys-prompts-for-friends/id6452384298" target="_blank" rel="noopener noreferrer">
                    <img src={downloadButton} alt="Download on the app store" style={{ width: '200px', height: '60px' }} />
                    </DownloadButton>
                </TopContainer>
                <BottomContainer>
                    <Space></Space>
                    <AnimatedButton onClick={handleDownload}>create your own prompts!</AnimatedButton>
                </BottomContainer>
            </Background>
        )
    }
    return (
        <Background>
                <TopContainer>
                    <TopForm>
                        <Bubble>
                            <BubbleContainerHeader>
                            <BubbleHeader>
                                <BubblePFPContainer>
                                    <BubblePFPImage src={account.pfp_url} alt="Profile"/>
                                </BubblePFPContainer>
                                <BubbleUserContainer>
                                    <UsernameDisplay account={account} />
                                    <Prompt>{prompt.text}</Prompt>
                                </BubbleUserContainer>
                            </BubbleHeader>
                            <BubbleEmoji>
                                {prompt.emoji}
                                </BubbleEmoji>
                            
                            </BubbleContainerHeader>
                            <BubbleTextContainer>
                                <BubbleTextArea placeholder="reply..." value={rizz} onChange={(e) => setRizz(e.target.value)} onFocus={onFocus} onBlur={onBlur}>
                                </BubbleTextArea>
                            </BubbleTextContainer>
                        {rizz && <SubmitButton onClick={handleSubmit}>Send!</SubmitButton>}
                        </Bubble>
                    </TopForm>
                </TopContainer>
                
                {!focused && 
                <BottomContainer>
                    <Space></Space>
                    <AnimatedButton onClick={handleDownload}>create your own prompts!</AnimatedButton>
                </BottomContainer>
                }
        </Background>
      );
}

function UsernameDisplay({ account }) {
    if (account.account_type === 'snapchat') {
        return <Username>{account.snapchat_username}</Username>;
    } else {
        return <Username>{account.instagram_username}</Username>;
    }
}

export default Reply
